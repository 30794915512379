// Detail.js
import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import JDP_PR1_1 from "../images/project1/JDP_BEG_1.jpg";
import JDP_PR1_2 from "../images/project1/JDP_BEG_2.jpg";
import JDP_PR1_3 from "../images/project1/JDP_BEG_3.jpg";
import JDP_PR1_4 from "../images/project1/JDP_BEG_4.jpg";
import JDP_PR1_5 from "../images/project1/JDP_BEG_5.jpg";
import JDP_PR1_6 from "../images/project1/JDP_BEG_6.jpg";
import JDP_PR1_7 from "../images/project1/JDP_BEG_7.jpg";
import JDP_PR1_8 from "../images/project1/JDP_BEG_8.jpg";
import JDP_PR1_9 from "../images/project1/JDP_BEG_9.jpg";
import JDP_PR1_10 from "../images/project1/JDP_BEG_10.jpg";
import JDP_PR1_11 from "../images/project1/JDP_BEG_11.jpg";
import JDP_PR1_12 from "../images/project1/JDP_BEG_12.jpg";
import JDP_PR2_1 from "../images/project2/JDP_UIB_1.jpg";
import JDP_PR2_2 from "../images/project2/JDP_UIB_2.jpg";
import JDP_PR2_3 from "../images/project2/JDP_UIB_3.jpg";
import JDP_PR2_4 from "../images/project2/JDP_UIB_4.jpg";
import JDP_PR2_5 from "../images/project2/JDP_UIB_5.jpg";
import JDP_PR2_6 from "../images/project2/JDP_UIB_6.jpg";
import JDP_PR2_7 from "../images/project2/JDP_UIB_7.jpg";
import JDP_PR2_8 from "../images/project2/JDP_UIB_8.jpg";
import JDP_PR2_11 from "../images/project2/JDP_UIB_11.jpg";
import JDP_PR2_12 from "../images/project2/JDP_UIB_12.jpg";

const projects = [
  {
    id: 1,
    title: "The ropes remain still",
    desc: "I started with this series trying to imaging the beautifull Flemish landscape. As this started to unfold, I started to see a project about loss and grief.",
    images: [
      JDP_PR1_1,
      JDP_PR1_2,
      JDP_PR1_3,
      JDP_PR1_4,
      JDP_PR1_5,
      JDP_PR1_6,
      JDP_PR1_7,
      JDP_PR1_8,
      JDP_PR1_9,
      JDP_PR1_10,
      JDP_PR1_11,
      JDP_PR1_12,
    ],
  },
  {
    id: 2,
    title: "'little' America",
    desc: "This series talks about how Belgium is influenced by the American landscape. We copy the USA in our big infrastructures as in the things that we do. We don’t have to go to Amerika, as it is already close by.",
    images: [
      JDP_PR2_1,
      JDP_PR2_2,
      JDP_PR2_3,
      JDP_PR2_4,
      JDP_PR2_5,
      JDP_PR2_6,
      JDP_PR2_7,
      JDP_PR2_8,
      JDP_PR2_11,
      JDP_PR2_12,
    ],
  },
];

const Detail = () => {
  const { id } = useParams();
  const carouselRef = useRef(null);

  const [project, setProject] = useState(null);
  const [displayImages, setDisplayImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    const projectData = projects.find((proj) => proj.id === parseInt(id));
    if (projectData) {
      setProject(projectData);
      const shuffledImages = projectData.images.sort(() => 0.5 - Math.random());
      setDisplayImages(shuffledImages.slice(0, 8));
      setLoading(false);
    }
  }, [id]);

  const handleArrowRightClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleArrowLeftClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const checkScroll = () => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.addEventListener("scroll", checkScroll);
      checkScroll();
      return () => {
        if (carouselRef.current) {
          carouselRef.current.removeEventListener("scroll", checkScroll);
        }
      };
    }
  }, []);

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="detail">
      <div className="detail-info">
        <div className="detail-title roman">{project.title}</div>
        <div className="detail-date roman">{project.desc}</div>
      </div>
      <div className="detail-carousel" ref={carouselRef}>
        <div className="detail-images">
          {loading
            ? [...Array(8)].map((_, index) => (
                <Skeleton key={index} height={200} width={300} />
              ))
            : displayImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt="Project"
                  onClick={() => openModal(image)}
                />
              ))}
        </div>
      </div>
      {canScrollLeft && (
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="detail-arrow-left"
          onClick={handleArrowLeftClick}
        />
      )}
      {canScrollRight && (
        <FontAwesomeIcon
          icon={faArrowRight}
          className="detail-arrow-right"
          onClick={handleArrowRightClick}
        />
      )}

      {isModalOpen && (
        <div
          className="modal"
          style={{ display: isModalOpen ? "block" : "none" }}
          onClick={closeModal}
        >
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-content">
            <img src={modalImage} alt="Full Screen" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Detail;
