//Homepage
import React from "react";
import HomepageImage from "../images/homepicture.png";

const Homepage = () => {
  return (
    <div className="homepage">
      <img src={HomepageImage} alt="Homepage" className="homepage-img" />
    </div>
  );
};

export default Homepage;
