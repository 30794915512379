//Homepage
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { FiMail } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import Footer from "./Footer";
const Detail = () => {
  return (
    <>
      <div className="detail">
        <div className="detail-info">
          <div className="detail-title roman">About</div>
          <div className="detail-date roman">
            I’m Photographer based in Belgium. I work on my own projects as I do
            also commercial work. Feel free to contact me on my Instagram or by
            mail. (If you want to buy a print, send an e-mail)
          </div>
          <div className="detail-icons">
            <a
              href="https://www.instagram.com/jarnodepaemeleere/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram /> jarnodepaemeleere
            </a>

            <a
              href="mailto:jarnodepaemeleere.info@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <FiMail /> jarnodepaemeleere.info@gmail.com
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Detail;
