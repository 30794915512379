import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Homepage from "./components/Homepage";
import NotFound from "./components/NotFound";
import Works from "./components/Works";
import Detail from "./components/Detail";
import About from "./components/About";

function App() {
  return (
    <div className="app-container">
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/project/:id" element={<Detail />}></Route>
        <Route path="/project/works" element={<Works />}></Route>
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
