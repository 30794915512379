//Homepage
import React from "react";
import { useState } from "react";

import JDP_PR3_1 from "../images/project3/JDP_WRK_1.jpg";
import white from "../images/project3/white.png";

const images = [JDP_PR3_1];

const Detail = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  return (
    <div className="detail">
      <div className="detail-info">
        <div className="detail-title roman">Works</div>
        <div className="detail-date roman">
          In Works you see experiments made with a technical camera / analog
          camera.
        </div>
        <div className="works-grid">
          <div className="works-grid-item white">
            <img
              src={white}
              alt="Project"
              className="works-grid-item-img white"
            />
          </div>
          {images.map((image, index) => (
            <div key={index} className="works-grid-item">
              <img
                onClick={() => openModal(image)}
                src={image}
                alt="Project"
                className="works-grid-item-img"
              />
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <div
          className="modal"
          style={{ display: isModalOpen ? "block" : "none" }}
          onClick={closeModal}
        >
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <div className="modal-content-works">
            <img src={modalImage} alt="Full Screen" />
            <div className="modal-text">
              <div className="detail-title-sm roman">Merel</div>
              <div>4x5 film</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Detail;
